import Editor from './editor'
import ListFilter from '@components/list-filter'
import {useTenant, useUsersSettings} from '@store/settings'
import {Button, Popconfirm, Space, Table} from 'antd'
import {Params, useDelete, useFetchGet} from 'helpers/api'
import PageHeader from '@components/page-header'
import {isSysAdmin} from 'helpers/role'
import {pageSizeOptions} from 'helpers/style'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'

const getColumns = ({tenant, setTenant, setSelectedItem, doDeleteUser, currentUser}) =>
  [
    {
      title: 'Company',
      render: (_, record) => (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation()
            setTenant({id: record.tid, name: record.t_name})
          }}
        >
          {record.t_name}
        </Button>
      ),
      hidden: !!tenant
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 200
    },
    {
      title: (
        <Button
          className="add"
          type="link"
          onClick={() => {
            setSelectedItem({tid: tenant?.id})
          }}
        >
          Add
        </Button>
      ),
      key: 'action',
      align: 'right',
      width: 200,
      render: (_, record) => {
        if (
          (record?.role === 'sysadmin' && currentUser?.role !== 'sysadmin') ||
          (record?.role === 'sysadminRO' && currentUser.role !== 'sysadmin' && currentUser.role !== 'sysadminRO')
        ) {
          return <></>
        } else {
          return (
            <Space size="middle">
              <Button type="link" onClick={() => setSelectedItem(record)}>
                Edit
              </Button>
              <Popconfirm
                title="Are you sure delete this?"
                placement="topRight"
                onConfirm={() => doDeleteUser(record.id)}
                okText="Yes"
                cancelText="No"
                disabled={record.id === currentUser.id}
              >
                <Button type="link" disabled={record.id === currentUser.id}>
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          )
        }
      }
    }
  ].filter(item => !item.hidden)

const Users = () => {
  const currentUser = useSelector(state => state.auth.user)
  const [tenant, setTenant] = useTenant()
  const [filter, setFilter, page, setPage, limit, setLimit] = useUsersSettings()
  const [users, setUsers] = useState(null)
  const [usersMeta, setUsersMeta] = useState(null)
  const [selectedItem, setSelectedItem] = useState()
  const fetchUsers = useFetchGet('usersview')
  const deleteUser = useDelete('users')

  const getUsers = () => {
    const params = Params({filter, limit, page, order: 'DESC'})
    if (isSysAdmin(currentUser) && tenant) params.append('tid', tenant.id)
    fetchUsers(params, response => {
      setUsers(response.usersview)
      setUsersMeta(response.meta)
    })
  }

  useEffect(() => {
    getUsers()
  }, [filter, page, limit, tenant, currentUser])

  const data = useMemo(() => {
    if (!users) return null
    return users
  }, [users])

  const doDeleteUser = id => {
    deleteUser(id, response => {
      getUsers()
    })
  }

  const columns = useMemo(() => {
    return getColumns({tenant, setTenant, setSelectedItem, doDeleteUser, currentUser})
  }, [tenant, currentUser])

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Users"
        subTitle=""
        backIcon={false}
        extra={[
          <ListFilter
            onFilter={value => {
              setFilter(value)
              setPage(1)
            }}
            filter={filter}
            tenant={tenant}
          />
        ]}
      />
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        loading={!data}
        pagination={{
          position: ['topRight', 'bottomRight'],
          total: usersMeta?.total,
          current: Number(usersMeta?.page) || 1,
          pageSize: limit,
          pageSizeOptions: pageSizeOptions,
          onChange: (_page, _limit) => {
            if (_page !== page) setPage(_page)
            if (_limit !== limit) setLimit(_limit)
            document.querySelector('main').scrollIntoView()
          }
        }}
      />
      <Editor
        user={
          selectedItem
            ? {...selectedItem, tid: currentUser?.role === 'admin' ? currentUser?.tid : selectedItem?.tid}
            : null
        }
        onClose={() => setSelectedItem()}
        onSuccess={() => getUsers()}
      />
    </>
  )
}

export default Users
