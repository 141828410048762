import {Button, Table} from 'antd'
import PageHeader from '@components/page-header'
import {Params, useFetchGet} from 'helpers/api'
import {isSysAdmin} from 'helpers/role'
import {paginationDefaults} from 'helpers/style'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {useTenantsSettings} from '@store/settings'
import ListFilter from '@components/list-filter'
const getColumns = handlers => [
  {
    title: 'Company',
    render: (_, record) => (
      <Button
        type="link"
        onClick={e => {
          e.stopPropagation()
          handlers.tenant(record)
        }}
      >
        {record.name}
      </Button>
    )
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    render: (text, row) => {
      return moment(row.created).local().format('yyyy/MM/DD h:mm:ss A')
    }
  }
]

const Tenants = ({handleTenantChange}) => {
  const user = useSelector(state => state.auth.user)
  const [tenants, setTenants] = useState(null)
  const [tenantsMeta, setTenantsMeta] = useState(null)
  const fetchTenants = useFetchGet('tenants')
  const [filter, setFilter, page, setPage, limit, setLimit] = useTenantsSettings()

  const getTenants = () => {
    const params = Params({filter, limit, page, orderBy: 'name', order: 'ASC'})
    fetchTenants(params, response => {
      setTenants(response.tenants)
      setTenantsMeta(response.meta)
      console.log('tenants=' + response.tenants)
    })
  }

  useEffect(() => {
    if (isSysAdmin(user)) getTenants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, filter])

  const columns = useMemo(() => {
    return getColumns({tenant: handleTenantChange})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.role])

  const rowKey = record => {
    return `${record?.id}`
  }

  const data = useMemo(() => {
    console.log('data=' + tenants)
    return tenants
  }, [tenants])

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Select a company to view dashboard..."
        subTitle=""
        backIcon={false}
        extra={[
          <ListFilter
            onFilter={value => {
              setFilter(value)
              setPage(1)
            }}
            filter={filter}
          />
        ]}
      />
      <Table
        data-testid="tenants-table"
        columns={columns}
        dataSource={data}
        loading={!data}
        pagination={paginationDefaults(tenantsMeta?.total, limit, tenantsMeta?.page, setPage, setLimit)}
        rowKey={record => rowKey(record)}
        rowClassName="antd-table-row"
      />
    </>
  )
}

export default Tenants
