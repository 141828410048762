import {CancelButton} from './styles'
import {Style} from './styles'
import {isSysAdmin} from 'helpers/role'

export const TenantTitle = ({user, tenant, onClearTenant}) => {
  if (isSysAdmin(user) && tenant) {
    return (
      <span onClick={onClearTenant}>
        <Style>
          Company: <span data-testid="selected-tenant">{tenant.name}</span> <CancelButton />
        </Style>
      </span>
    )
  } else {
    return <></>
  }
}
